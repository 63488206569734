import React from 'react';

interface Props {
  height?: number;
  width?: number;
}

function BossIcon(props: Props) {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70.9 80"
      xmlSpace="preserve"
      fill="#419d3e"
      {...props}
    >
      <path
        d="M30.8,80c-1.2-0.1-2.5-0.2-3.7-0.3c-8.7-0.6-17.2-2.2-25.5-4.9c-1.3-0.4-1.6-0.9-1.5-2.2c0.3-5.7,2-11,5.5-15.6
	c0.5-0.6,1-1,1.8-0.8c0.6,0.1,1.1,0.6,1.1,1.2c0,0.5-0.1,1-0.4,1.4c-2.4,3.2-3.9,6.7-4.6,10.7C3.1,72,3,72,5.4,72.7
	c1.5,0.4,3.1,0.9,4.7,1.3c0-2,0-3.9,0-5.7c0-1.4,0.5-2.2,1.6-2.2c1,0,1.6,0.7,1.6,2.2c0,2.1,0,4.2,0,6.4c5.1,1.1,10.3,1.7,15.6,2
	c-0.3-0.8-0.5-1.5-0.8-2.1c-0.7-1.4-0.6-2.8-0.3-4.2c1.1-5.3,2.1-10.6,3.1-15.8c0.1-0.4,0-0.9-0.1-1.3c-0.6-1.8-1.3-3.7-2-5.6
	c-1.4,0.3-2.9,0.5-4.3,1c-2.2,0.7-4.4,1.6-6.6,2.5c-0.4,0.2-0.9,0.4-1.3,0.3c-0.7,0-1.2-0.5-1.3-1.2c-0.2-0.7,0.1-1.4,0.9-1.7
	c2.1-0.9,4.3-1.8,6.5-2.6c0.6-0.2,1.2-0.4,1.9-0.6c-0.3-0.2-0.5-0.4-0.8-0.5c-4.2-2.9-7.2-6.6-8.4-11.7c-0.1-0.2-0.3-0.5-0.6-0.6
	c-3.9-1.5-5.8-6.2-4.1-10c0.4-0.9,1-1.3,2-1.3c0.7,0,1.5,0,2.3,0c0-0.8,0-1.6,0-2.4c0-2.9,0.5-5.6,1.8-8.1c2.4-4.6,6.2-7.5,11-9.2
	c6-2,11.9-1.9,17.7,1c5.7,2.8,8.8,7.6,9.8,13.8c0.3,1.6,0.3,3.2,0.4,5c0.7,0,1.5,0,2.3,0c0.9,0,1.5,0.4,1.9,1.2
	c1.8,3.7-0.2,8.6-4.1,10c-0.5,0.2-0.7,0.4-0.8,0.9c-1.2,4.9-4.1,8.6-8.2,11.5c-0.2,0.1-0.4,0.3-0.7,0.5c1.5,0.5,2.9,0.9,4.3,1.4
	c5.7,2.1,10.6,5.3,14.4,10.2c3.7,4.7,5.5,10.1,5.8,16.1c0,1-0.3,1.5-1.3,1.9c-5.4,1.9-11,3.2-16.7,4c-4,0.5-8,0.8-12,1.2
	c-0.2,0-0.5,0.1-0.7,0.1C37,80,33.9,80,30.8,80z M24.6,19.8c-1.1,2.9-3.4,4.1-6.3,4.5c0,1.9-0.1,3.8,0,5.6c0.1,1.2,0.2,2.4,0.6,3.6
	C21.3,41.6,32,48.2,42,43.8c4.9-2.1,8.6-5.6,10-10.9c0.5-1.8,0.3-3.7,0.5-5.6c0.1-1,0-2,0-3.1c-0.3,0-0.6,0-0.8,0
	c-4.7,0-9.3,0.1-14,0c-4.6-0.1-8.9-1.4-12.6-4.1C24.9,20,24.8,19.9,24.6,19.8z M42.2,47.6c-0.7,2-1.4,3.8-2,5.6
	C40,53.6,40,54.1,40,54.5c1.1,5.7,2.2,11.3,3.2,17c0.1,0.6,0,1.3-0.2,2c-0.3,1.1-0.7,2.1-1.1,3.2c5.1-0.3,10.1-0.9,15-1.9
	c0-0.3,0-0.6,0-0.9c0-2,0-4,0-6c0-1,0.7-1.8,1.6-1.8c0.9,0,1.5,0.7,1.6,1.8c0,1.6,0,3.2,0,4.8c0,0.5,0,0.9,0,1.5
	c2.6-0.7,5-1.4,7.5-2.1c0-0.4,0-0.7-0.1-1C67,65.8,65,61,61.3,57c-4-4.3-9-6.9-14.6-8.4C45.2,48.2,43.7,47.9,42.2,47.6z M18.3,21.1
	c2.2-0.3,3.4-1.5,3.5-3.7c0-0.2,0-0.4,0-0.5c0-0.7,0.3-1.3,1-1.6c0.7-0.3,1.3-0.1,1.8,0.4c3.9,3.9,8.7,5.4,14.1,5.5
	c4.4,0,8.7,0,13.1,0c0.2,0,0.4,0,0.8,0c-0.1-1.5-0.2-3-0.4-4.5c-1-5.9-4.1-10-9.7-12.2c-3.4-1.3-7-1.5-10.6-0.9
	c-3,0.6-5.8,1.7-8.3,3.6C19.2,10.7,17.8,15.5,18.3,21.1z M34,55.3c0,0.1-0.1,0.2-0.1,0.3c-1,5.4-2.1,10.8-3.1,16.1
	c-0.1,0.3,0,0.6,0.1,0.9c0.4,1.2,0.9,2.4,1.3,3.7c0.1,0.4,0.3,0.5,0.7,0.5c1.7,0,3.4,0,5.1,0c0.5,0,0.6-0.2,0.8-0.6
	c0.4-1.1,0.8-2.1,1.1-3.2c0.2-0.5,0.2-1.1,0.1-1.7c-0.3-2-0.8-4.1-1.1-6.1c-0.6-3.3-1.3-6.6-1.9-10C35.9,55.3,35,55.3,34,55.3z
	 M32.3,48.2c0.1,0.4,0.3,0.8,0.5,1.3c0.3,0.9,0.5,2,1.1,2.5c0.7,0.4,1.8,0.1,2.7,0.1c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.2,0.5-0.3
	c0.5-1.2,0.9-2.4,1.3-3.5C36.5,48.2,34.4,48.2,32.3,48.2z M55.7,24.3c0,1.5,0,3.1,0,4.6c1.5-0.8,2.3-3,1.7-4.6
	C56.8,24.3,56.3,24.3,55.7,24.3z M13.5,24.3c-0.6,1.6,0.2,3.9,1.5,4.5c0-1.5,0-3,0-4.5C14.5,24.3,14,24.3,13.5,24.3z"
      />
      <path d="M9.7,53.4c0-0.9,0.7-1.6,1.6-1.5c0.9,0,1.5,0.7,1.5,1.6c0,0.9-0.7,1.6-1.6,1.6C10.3,55,9.6,54.3,9.7,53.4z" />
    </svg>
  );
}

BossIcon.defaultProps = {
  height: 80,
  width: 70,
};

export default BossIcon;
