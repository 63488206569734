import React from 'react';

function Logo() {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 190 31.8"
      xmlSpace="preserve"
      height={32}
      width={190}
    >
      <g>
        <polygon
          style={{
            fill: `#409F3D`,
          }}
          className="st0"
          points="4.6,12.6 12.4,14 11,20.2 23.8,19.7 29.6,15.9 30.3,7 20.1,7 17.4,3.1 2.3,2.2 	"
        />
        <path
          className="st1"
          d="M42.9,26.5h-2V10.3h2c5.3,0,8.1,3.5,8.1,8.1C51.1,23.2,48.2,26.5,42.9,26.5 M42.9,5.2h-8.4v26.5h8.4
		c9,0,14.5-5.7,14.5-13.2C57.4,10.9,51.9,5.2,42.9,5.2"
        />
        <path
          className="st1"
          d="M66.4,21.6l2.7-9.3h0.2l2.7,9.3H66.4z M66.2,5.2l-9.7,26.5h6.6l1.7-5.1h9l1.7,5.1h6.6L72.4,5.2H66.2z"
        />
        <path
          className="st1"
          d="M88,5.2h-2.6v26.5h6.4V8.9C91.8,6.9,90.1,5.2,88,5.2"
        />
        <polygon
          className="st1"
          points="111,21.9 110.6,23.7 110.4,23.7 110,21.9 103.5,5.2 95,5.2 95,31.6 101.3,31.6 101.3,14 101.6,14
		102.3,16 108.5,31.6 112.6,31.6 118.9,15.9 119.4,14.3 119.6,14.3 119.6,31.6 126,31.6 126,5.2 117.5,5.2 	"
        />
        <path
          className="st1"
          d="M135.3,8.9c0-2.1-1.7-3.8-3.8-3.8H129v26.5h16.2v-5.1h-9.8V8.9z"
        />
        <path
          className="st1"
          d="M161.6,5.2h-13.2v26.5h17v-5.1h-10.7v-5.8h10.1v-5.1h-10.1v-5.2h10.7V8.9C165.4,6.9,163.7,5.2,161.6,5.2"
        />
        <polygon
          className="st1"
          points="183.9,5.2 177.9,16.2 172.1,5.2 166,5.2 174.8,21.7 174.8,31.6 181.1,31.6 181.1,21.8 190,5.2 	"
        />
        <path
          className="st1"
          d="M28.4,14.8c0,1.2-0.9,2.1-2.1,2.1h-3.9V9.4h6V14.8z M4.2,4.2h12.1c0.7,0,1.4,0.4,1.7,1.1h-2
		c-3.4,0-6.3,2.8-6.3,6.3v0.2H6.1c-1.1,0-1.9-0.9-1.9-1.9V4.2z M18.5,27.6h-4.6V22c0.7-0.6,1.4-0.9,2-0.9h2.3v4.7
		C18.2,26.4,18.3,27,18.5,27.6 M18.2,9.4V12c-0.6-0.2-1.3-0.3-1.9-0.3h-2.4v-0.2c0-1.2,0.9-2.1,2.1-2.1H18.2z M18.2,16.7v0.2h-2.3
		c-0.6,0-1.3,0.1-2,0.3v-1.4h2.4C16.8,15.9,17.5,16.2,18.2,16.7 M22.3,5.2c-0.4-3-2.9-5.2-6-5.2H0v9.8c0,3.4,2.7,6.1,6.1,6.1h3.6
		v15.9h18.9l-3.4-2.2c-1-0.6-2.9-2.4-2.9-3.8v-4.7h3.9c3.5,0,6.3-2.8,6.3-6.3V5.2H22.3z"
        />
      </g>
    </svg>
  );
}

export default Logo;
