import React from 'react';

function Pracodawca() {
  return (
    <svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 112.1 80"
      xmlSpace="preserve"
      height={80}
      width={120}
      fill="#419d3e"
    >
      <path
        d="M26.1,80c-0.9-0.6-1.1-1.4-1.1-2.5c0-2.2,0-4.4,0-6.6c0-4.1,1.9-7,5.6-8.7c3.3-1.5,6.6-3,10.2-4.6c-2.1-0.6-4-1-5.8-1.7
	c-1.5-0.6-2.6-0.4-3.7,0.9c-0.7,0.9-1.7,1.6-2.6,2.4c-2.4,2.2-5.2,2.2-7.6,0c-2-1.8-4-3.7-6-5.6c-0.6-0.6-1-0.6-1.7-0.3
	c-2.4,1.2-4.8,2.3-7.3,3.4c-1.9,0.8-2.8,2.2-2.7,4.3c0,2.6,0,5.3,0,7.9c0,1.4-0.6,2.2-1.7,2.2c-1,0-1.7-0.8-1.7-2.2
	c0-2.7,0-5.5,0-8.2c0-3.2,1.5-5.4,4.4-6.8c3.3-1.6,6.7-3.1,10-4.6c0.5-0.2,0.9-0.6,1.4-0.9c0-0.1-0.1-0.2-0.1-0.3
	c-2.2-0.4-4.4-0.8-6.6-1.2c-1.2-0.2-2.5-0.4-3.7-0.7c-1.3-0.2-1.7-0.8-1.7-2c0-7.2-0.1-14.3,0-21.5C3.8,11.9,11.8,3.2,22.5,2
	c5.4-0.6,10.4,0.7,14.8,3.9c0.3,0.2,0.6,0.4,0.9,0.6c11.1-8.6,22.3-8.7,33.6-0.3c1-2.1,2.5-3.6,4.9-4.2c0.8-0.2,1.5-0.2,2.3-0.2
	c6.6,0,13.3,0,19.9,0c2,0,2.5,0.4,2.5,2.5c0,1.4,0,2.7,0,4.1c0,1.7-0.4,3.2-1.4,4.6c-0.3,0.4-0.4,1-0.4,1.5c-0.1,4.8,0,9.6-0.1,14.5
	c-0.1,3.9-1.7,7.2-4.4,9.9c-0.6,0.6-0.9,1.3-0.9,2.1c0.1,1.4,0,2.7,0,4.1c0,1.2,0.5,2,1.7,2.4c3.7,1.4,7.4,2.9,11,4.4
	c3.6,1.5,5.1,3.7,5.2,7.6c0,3,0,6,0,9c0,0.4,0.1,1-0.2,1.3c-0.4,0.5-1,1.1-1.5,1.2c-0.8,0.1-1.4-0.4-1.6-1.3
	c-0.1-0.6-0.1-1.2-0.1-1.8c0-2.9,0-5.7,0-8.6c0-2.3-0.8-3.4-2.9-4.3c-3.4-1.3-6.7-2.7-10.1-4c-2.1,2.7-4.7,4.4-8,5.2
	c-3.2,0.8-6.4,0.7-9.5-0.4c-0.6-0.2-1.5-0.2-2.1-0.1c-2.1,0.5-4.1,1.2-6.4,1.8c0.5,0.3,0.9,0.4,1.2,0.6c2.9,1.3,5.9,2.7,8.8,4
	c3.8,1.7,5.7,4.6,5.7,8.7c0,2.3,0,4.5,0,6.8c0,1-0.3,1.8-1.2,2.3c-0.4,0-0.7,0-1.1,0c-0.9-0.7-1.1-1.6-1-2.6c0-2.2,0-4.4,0-6.7
	c0-2.5-1.2-4.3-3.5-5.3c-1.9-0.9-3.9-1.7-5.8-2.6c-0.7-0.3-1.2-0.3-1.9,0.1c-3.5,2.2-7,4.4-10.5,6.6c-3.5,2.2-6.9,2.2-10.4,0
	c-3.5-2.2-7.1-4.5-10.7-6.6c-0.4-0.2-1.1-0.3-1.5-0.1c-2.1,0.8-4.1,1.8-6.1,2.7c-2.3,1.1-3.4,2.9-3.4,5.4c0,2.3,0,4.6,0,6.9
	c0,1-0.3,1.8-1.1,2.3C26.8,80,26.4,80,26.1,80z M44.4,55.2c0.1-1.3,0-2.5,0.2-3.6c0.1-1.1-0.3-1.7-1.2-2.4c-3.2-2.3-5.3-5.4-6.7-9.1
	c-1.2-3.3-1.1-6.7-1.1-10.1c0-4.1,0.5-4.7,4.5-5.7c5.2-1.3,10.1-3.3,13.8-7.6c0.7-0.8,1.7-0.8,2.4-0.2c0.7,0.6,0.7,1.5,0.1,2.4
	c-1.8,2.4-4.2,4-6.8,5.4c-3,1.6-6.2,2.7-9.6,3.4c-0.5,0.1-1,0.2-1,0.9c0.1,2-0.1,3.9,0.1,5.9c0.6,8.3,6.8,14.6,15,15.2
	c7.8,0.6,15.1-4.6,16.8-12.6c0.6-2.6,0.3-5.4,0.5-8.1c0.1-1-0.3-1.3-1.2-1.5c-3.5-0.8-6.9-2-10.1-3.8c-1.1-0.6-1.4-1.5-1-2.4
	c0.4-0.9,1.4-1.2,2.5-0.6c3.2,1.7,6.6,2.9,10.2,3.7c2,0.5,2.9,1.7,2.9,3.8c0,1.8,0,3.6,0,5.3c-0.1,6.6-2.9,11.9-8.2,15.8
	c-0.2,0.2-0.6,0.4-0.6,0.6c0,1.6-0.3,3.2,0.4,4.8c3.2-0.9,6.3-1.8,9.4-2.7c0.9-0.2,1.2-0.6,1.1-1.5c0-8.5,0-17,0-25.5
	c0-1.3-0.1-2.7-0.3-4c-1.7-12.9-15.7-21.4-28-17c-9,3.2-14.6,11.1-14.7,20.7c0,8.7,0,17.3,0,26c0,0.3,0,0.6,0.1,0.9
	C37.2,53.1,40.6,54.1,44.4,55.2z M16,44.7c0.2-1.7,0.2-3-1.3-4.2c-1.6-1.3-2.9-3.1-3.9-4.9c-2.1-3.7-2-7.8-1.7-11.8
	c0.1-0.9,0.7-1.9,1.3-2.6c0.7-1,1.7-1.8,2.6-2.6c0.8-0.8,1.8-0.8,2.5-0.1c0.7,0.7,0.7,1.6-0.1,2.5c-0.7,0.7-1.5,1.4-2.1,2.1
	c-0.4,0.5-0.7,1.2-0.8,1.9c-0.1,1.4-0.1,2.8,0,4.2c0.4,8.4,9.1,14.1,16.9,11.2c0.8-0.3,1.1-0.7,1.1-1.6c0-5.2,0-10.3,0.1-15.5
	c0-0.7-0.4-0.8-0.9-1c-2.8-1.4-5.5-2.8-8.2-4.4c-1.1-0.6-2-1.5-2.9-2.4c-0.7-0.7-0.7-1.6-0.1-2.3c0.6-0.7,1.5-0.7,2.3-0.1
	c0.4,0.3,0.7,0.6,1,0.9c2.8,2.4,5.9,4,9.4,5.3c0.9-3.8,2.6-7.1,5-10.1C31.4,5.6,26.4,4.4,21,5.6c-8.5,2-14,9-14,17.7
	c0,6.3,0,12.5,0,18.8c0,0.3,0,0.6,0,1C9.9,43.6,12.9,44.2,16,44.7z M96.1,15.7c-0.1,0-0.2,0-0.3,0c-3.9,0.1-7.9,0.1-11.8,0.1
	c-1.3,0-2-0.6-2-1.6c0-1.1,0.7-1.7,2-1.7c1.3,0,2.7,0,4,0c2,0,4,0,6,0c1.9,0,3.4-1,3.7-2.6c0.3-1.5,0.2-3.1,0.3-4.7
	c-0.5,0-0.7-0.1-0.9-0.1c-6.2,0-12.4,0-18.6,0c-0.3,0-0.6,0-0.9,0.1c-2.6,0.4-3.8,3.2-2.3,5.3c2.8,3.9,4.3,8.3,4.5,13.1
	c0.1,2.2,0,4.4,0,6.5c0.5,0.2,0.8,0.3,1.1,0.4c1.8,1.3,3.5,1.2,5.4,0c0.8-0.6,1.8-0.3,2.2,0.5c0.5,0.7,0.4,1.8-0.5,2.2
	c-1.1,0.6-2.4,1.3-3.6,1.4c-1.4,0.2-2.9-0.3-4.4-0.4c0,1.7,0,3.6,0,5.5c7.2,2.1,14.7-2.2,15.8-9.5c0.7-4.8,0.3-9.7,0.3-14.5
	C96.2,15.8,96.2,15.8,96.1,15.7z M47.9,51.9c0.4,4.4-1.5,7.2-5.6,8.7c0.3,0.2,0.4,0.4,0.5,0.4c3.1,1.9,6.1,3.9,9.2,5.8
	c2.1,1.3,4.3,1.2,6.4-0.1c3-1.9,6-3.7,8.9-5.6c0.2-0.1,0.4-0.3,0.6-0.6c-4.1-1.4-5.9-4.3-5.6-8.6C57.5,53.7,52.8,53.7,47.9,51.9z
	 M17.8,51.6c1.7,1.6,3.6,3.4,5.5,5.1c1.2,1.1,2,1,3.2,0c1.1-1,2.2-1.9,3.1-3c0.4-0.4,0.6-1,0.6-1.5c0.1-2.6,0-5.2,0-7.8
	c0-0.3-0.1-0.6-0.1-1c-3.6,1.3-7.2,1.2-10.9,0c0,1.5,0.2,2.9-0.1,4.3C19.1,49,18.4,50.2,17.8,51.6z M90.9,42.1
	c-3.5,1.7-7.1,2.1-10.9,1.3c0,2.3,0,4.5,0,6.6c0,3.3,0,3.4,3.4,3.5c3.6,0.1,6.8-1.1,9.3-4C90.4,47.5,91.1,44.8,90.9,42.1z"
      />
      <path
        d="M55.2,46.1c-3.6,0-7.7-3-8.7-6.4c-0.4-1.2,0.3-2.2,1.5-2.3c4.7,0,9.5,0,14.2,0c1.2,0,1.9,1.1,1.5,2.3
	C62.7,43.2,58.8,46.1,55.2,46.1z M51.2,40.9c2,2.7,6.5,2.3,7.8,0C56.5,40.9,53.9,40.9,51.2,40.9z"
      />
      <path
        d="M47.2,26.8c1.8,0.1,3.2,0.9,4.1,2.6c0.4,0.9,0.2,1.8-0.5,2.2c-0.8,0.5-1.6,0.3-2.2-0.5c-1.1-1.4-1.5-1.4-2.6,0
	c-0.6,0.8-1.5,1-2.2,0.5c-0.8-0.5-1-1.3-0.6-2.2C43.9,27.7,45.3,26.9,47.2,26.8z"
      />
      <path
        d="M63.4,26.8c1.7,0.1,3.1,0.9,3.9,2.6c0.4,0.8,0.3,1.6-0.5,2.1c-0.8,0.5-1.6,0.3-2.2-0.4c-1.1-1.4-1.5-1.4-2.7,0
	c-0.6,0.7-1.3,0.9-2.2,0.4c-0.8-0.5-1-1.2-0.7-2.1C59.7,27.9,61.4,26.8,63.4,26.8z"
      />
      <path
        d="M24.9,36.8c-1.5-0.5-3-1-4.4-1.6c-0.9-0.4-1.2-1.2-0.7-2.2c0.4-0.9,1.3-1.2,2.3-0.7c1.9,1,3.7,1,5.6,0
	c0.9-0.5,1.8-0.1,2.2,0.7c0.4,0.8,0.2,1.8-0.7,2.2C27.9,35.8,26.5,36.3,24.9,36.8z"
      />
      <path
        d="M19.4,25.8c0,1.7-0.5,2.5-1.6,2.6c-1.2,0-1.7-0.8-1.7-2.6c0-0.3,0-0.6,0-0.9c0.1-1,0.8-1.6,1.7-1.6c0.9,0,1.5,0.6,1.6,1.6
	C19.4,25.2,19.4,25.5,19.4,25.8z"
      />
      <path
        d="M92.5,24.1c0,0.3,0,0.7-0.1,1c-0.1,0.9-0.7,1.4-1.6,1.4c-0.9,0-1.6-0.5-1.7-1.4c-0.1-0.7-0.1-1.4,0-2.1
	c0.1-0.9,0.7-1.5,1.6-1.5c1,0,1.5,0.5,1.6,1.5C92.5,23.3,92.5,23.7,92.5,24.1z"
      />
    </svg>
  );
}

export default Pracodawca;
